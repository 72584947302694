<script>

export default {
	name: 'LangSwitch',
	
	components: {
	
	},
	
	data() {
		return {
			langs:[{id:1,title:"czech",shorts:"cz"},{id:2,title:"slovak",shorts:"sk"},{id:3,title:"english",shorts:"en"}],
			actLang:''
		}
	},
	
	props: {'propActLang': String},
	
	mounted:function(){
		if(localStorage.Lang!=null) {this.actLang=localStorage.Lang} else { this.actLang='sk'}
	},
	
	methods: {
		
		setLang:function(langId){
			localStorage.Lang=langId;
			this.actLang=langId;
			this.$emit('update:propActLang', langId)
		},
	
	},	
	
	watch: {
		
	},

	computed:{
		
	},
	
}

</script>

<template>
			
	<div class="col-2 text-right langs">
		<span v-for="(i,index) in langs"  v-bind:key="i" v-on:click="setLang(i.shorts)" :title="i.title"><b v-if="actLang==i.shorts">{{i.shorts}}</b><span v-else>{{i.shorts}} </span><span v-if="(index+1)<langs.length">|</span></span>
	</div>
	
</template>


