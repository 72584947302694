<script>

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import Popper from 'popper.js'
import 'bootstrap/dist/js/bootstrap.min.js'
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faVideo, faUserFriends, faListAlt, faFileAlt, faGlobe, faPlayCircle, faHome, faPen, faArrowAltCircleRight, faSignOutAlt, faSignInAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faEye, faEyeSlash, faUser, faQrcode, faCamera, faThList } from '@fortawesome/free-solid-svg-icons';
library.add( faBars, faVideo, faUserFriends, faListAlt, faFileAlt, faGlobe, faPlayCircle, faHome, faPen, faArrowAltCircleRight, faSignOutAlt, faSignInAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faEye, faEyeSlash, faUser, faQrcode, faCamera, faThList)
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import $ from 'jquery';

import myLangs from './components/LangSwitch.vue'

//import myQuestions from './components/Questions.vue'

//general functions
import MixinGeneralFunct from './mixins/GeneralFunctions';

import axios from 'axios';

var sha512 = require('js-sha512');

export default {
	name: 'App',
	mixins: [ MixinGeneralFunct ],
	components: {
		myLangs,
		//myQuestions,
		"font-awesome-icon":FontAwesomeIcon
	},
	
	
	data() {
		return {
			prefix:'',
			user:[],
			checkRegisterUser:null,
			checkRegisterEvent:null,
			eventCounts:[{streams:0,documents:0,expo:0,program:0,speakers:0,records:0}],
			
			cookiesTime: 259200,
			event:[],
			selectedAccessCode:null,
			alerts:[],
			
			menuIsOpened:false,

			token:'',

			t0:0,
			t1:0,
		
		}
	},
	
	mounted:function(){

		this.loadDefaultValues();
		
	},

	methods: {
		
		//default data
		//presunute pod login
		loadDefaultValues:function(){
			var self = this;
			axios.get('/app/includeData/Common/getDefaultDataLogin.php')
			.then(function (response) {
				//console.log("loadDefData");
				//console.log(response.data);
				
				self.user = response.data.user;
				self.event = response.data.event;
				self.eventCounts = response.data.eventItemCounts;
				console.log(self.event)
				
				if(self.event.isReg){
					self.resetLoadingInterval();
					self.resetAlertInterval();
				}else{
					//location.replace("/login");
					self.$router.push({ path: '/login' })
				}
				
			})
				.catch(function (error) {
				console.log(error);
			});
		},
		
		//modal new question
		loadModalLogin:function(){
			//console.log('ok');
			
			axios({
				method: 'post',
				url: '/app/includeData/Common/getDefaultDataLogin.php'
			})
			.then(response => {
				if (response.data.error) {
					console.log('error', response.data.error)
				} else {
					//console.log('success2', response.data);
					self.user = response.data.user;
					
					$('#myModalLogin').addClass('modal');
					$('#myModalLogin').modal({backdrop: "static"});
				}
			})
			.catch(error => {
				console.log(error.response)
			});
			
		},
		
		checkData:function(){
			if(this.user.email && this.user.password){
				return true;
			}else{
				this.createMessageBoxTrans('warning',null,'fillInAllData');
				return false;
			}
		},
		
		//messagebox v1.1
		createMessageBoxTrans: function(kind,object,message) {
			var content = "";
			content += "<div class='alert alert-"+kind+"' role='alert'>";
			content += this.$t('message.'+message);
			content += "</div>";
			
			var rand = this.getRandomInt(600000);
			$('#msg').append("<div class='msg"+rand+"'></div>");
			$('.msg'+rand).html("").append(content).fadeIn(100).delay(6000).fadeOut(300);
		},
		
		// zatvaranie modalbox
		closeModalBoxSecure:function(myform){
			$("#"+myform).modal("hide");
			$("#"+myform).removeClass("modal");
		},
		
		//zobrazi heslo
		showPswd: function(){
			this.user.showInput = this.user.showInput===true?false:true;
		},
			
		
		//logout event
		logoutEvent:function(){
		
			var self = this;
			axios.get('/app/includeData/Common/controlEventUnReg.php')
			.then(function (response) {
				//console.log("loadDefData");
				//console.log(response.data);
				
				
				if(response.data.isOut){
					clearInterval(self.alertsInterval);
					clearInterval(self.checkInterval);
					self.event.isReg=false;
					
					self.remCookie("eventRegCode");
					self.remCookie("eventReg");
					self.remCookie("creatorName");
					
					self.selectedAccessCode='';
					
					self.loadOnRegisterUser();
					self.loadOnRegisterEvent();
					
					
					self.closeModalBoxSecure('myModalLogOutEvent');
					window.location.href = '/login';
//					self.$router.push({ path: '/' })
				}
				
				//tu pokracuj
				
				
				/*self.user = response.data.user;
				
				self.loadDefaultValues();
				
				self.setCookie("eventRegCode", '', 0);
				self.setCookie("eventReg", '', 0);
				self.setCookie("creatorName", '', 0);
				self.eventIsRegistered=false;
				self.loadDefaultValues();
				self.selectedAccessCode='';
				clearInterval(self.loadInterval);*/
				//setTimeout(function(){ window.location.href = window.location.protocol+'//'+window.location.hostname+'/'; }, 100);
			})
				.catch(function (error) {
				console.log(error);
			});
		
		},
		
		
		
		//prihlasenie admina
		signMe:function(){
							
			if(this.checkData()){
				//var basicData = JSON.stringify(this.basicData);
				var bodyFormData = new FormData();
				//bodyFormData.set('data',basicData);
				bodyFormData.set('email',this.user.email);
				bodyFormData.set('p',sha512(this.user.password));
				//console.log(this.user.password);
				//console.log(sha512(this.user.password));
				this.user.password = '';
				//console.log(this.user.password);
				
				var self = this;
				axios({
					method: 'post',
					url: '/app/includeData/Common/controlLogin.php',
					data: bodyFormData
				})
				.then(response => {
					if(response.data.error){
							console.log('error', response.data.error)
					}else{
						//console.log(response.data);
						
						if(response.data.resCode == 3){
							self.createMessageBoxTrans("warning",null,'badInputData');
						}else if(response.data.resCode == 1){
							self.createMessageBoxTrans("success",null,'successLogin');
							
							self.loadOnRegisterUser(); //pre Stream.vue
							//self.loadOnRegisterEvent(); //pre Uvod.vue
							
							self.loadDefaultValues();
							self.closeModalBoxSecure('myModalLogin');
							//setTimeout(function(){ window.location.href = window.location.protocol+'//'+window.location.hostname+'/'; }, 800);
						}else if(response.data.resCode == 2){
							self.createMessageBoxTrans("danger",null,'UnsuccessLogin');
						}else if(response.data.resCode == 0){
							self.createMessageBoxTrans("danger",null,'badLoginRefreshForm');
						}else if(response.data.resCode == 4){
							self.createMessageBoxTrans("danger",null,'AccountIsNotActivated');
						}else if(response.data.resCode == 5){
							self.createMessageBoxTrans("danger",null,'AccountIsBlocked');
						}else if(response.data.resCode == 6){
							self.createMessageBoxTrans("danger",null,'AccountExpired');
						}else if(response.data.resCode == 7){
							self.createMessageBoxTrans("danger",null,'NumberOfLoginsExceeded');
						}else if(response.data.resCode == 8){
							self.createMessageBoxTrans("danger",null,'EmailDoesNotExist');
						}else if(response.data.resCode == 9){
							self.createMessageBoxTrans("danger",null,'BadPasswordLength');
						}
						
						/*if(response.data.resCode==0 || response.data.resCode>1){
							var lineDrawingd = anime({
								targets: '#login-box',
								translateX: 20,
								duration: 50,
								easing: 'linear',
								direction: 'alternate',
								loop: 4
							});	
							this.loadDefaultValues();
						}*/
						
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
			
			}
			
		},
		
		//odhlasenie reg ucastnika
		logOutMe:function(){
			
			this.t0 = performance.now()
			
			//loader
			$('#preloader').show();
			
			
			var self = this;
			axios.get('/app/includeData/Common/controlLogout.php')
			.then(function (response) {
				//console.log("loadDefData");
				//console.log(response.data);
				self.t1 = performance.now()
				
				//console.log("ExTime " + (self.t1 - self.t0) + " milliseconds.")
				
				//self.setCookie("eventRegCode", '', 0);
				//self.setCookie("eventReg", '', 0);
				//self.setCookie("creatorName", '', 0);
				//self.eventIsRegistered=false;
				
				self.selectedAccessCode='';
				
				self.user = response.data.userData;
				
				//self.loadQuestionsOfMyQuestions();
				
				self.loadOnRegisterUser();
				//self.loadOnRegisterEvent();
				
				//hide loader
				$('#preloader').fadeOut(200);
				
				//self.$router.push({ path: '/' })
				//setTimeout(function(){ window.location.href = window.location.protocol+'//'+window.location.hostname+'/'; }, 100);
			})
				.catch(function (error) {
				console.log(error);
			});
		},
		
		
		//kontrola platnosti registracie eventu
		checkReg:function(){
			
			var self = this;
			axios.get('/app/includeData/Common/checkConn.php')
			.then(function (response) {
				//console.log("checkReg");
				//console.log(response.data);
				
				if(!response.data.isReg){
					self.logoutEvent();
					self.loadOnRegisterEvent();
					//setTimeout(function(){ window.location.href = window.location.protocol+'//'+window.location.hostname+'/'; }, 300);
				}

			})
				.catch(function (error) {
				console.log(error);
			});
		},
		
		//reset check interval
		resetLoadingInterval:function(){
			clearInterval(this.checkInterval);
			this.checkInterval = setInterval(this.checkReg, 10000);
		},
		
		
		//reset alert interval
		resetAlertInterval:function(){
			clearInterval(this.alertsInterval);
			this.alertsInterval = setInterval(this.loadAlerts, 8000);
		},
		
		//funkcia zabezpeci nacitanie otazok bez refreshu stranky
		//loadQuestionsOfMyQuestions:function(){
		//	if(this.myFunkcionToLoadQ){
		//		this.myFunkcionToLoadQ=false;
		//	}else{
		//		this.myFunkcionToLoadQ=true;
		//	}
		//},
		
		loadOnRegisterUser:function(){
			if(this.checkRegisterUser){
				this.checkRegisterUser=false;
			}else{
				this.checkRegisterUser=true;
			}
		},
		
		//funkcia zabezpeci nacitanie registrovaneho obsahu eventu
		//Uvod.vue
		loadOnRegisterEvent:function(){
			if(this.checkRegisterEvent){
				this.checkRegisterEvent=false;
			}else{
				this.checkRegisterEvent=true;
			}
		},
		
		//alerts data
		loadAlerts:function(){
			var self = this;
			axios.get('/app/includeData/Alerts/getValidAlerts.php')
			.then(function (response) {
				//console.log("loading Alerts");
				//console.log(response.data);
				
				self.alerts = response.data.basicData;
				//console.log("self.alerts");
				//console.log(response.data.basicData);
				
				//ziskam uz vymazanie cookies
				var x = self.getCookie("closedAlerts"+response.data.eventId);   
				if (x != ''){
					var oldCookies = x.split(",");
					//console.log("oldCookies")
					//console.log(oldCookies)
					
					
					for(var i = 0; i < oldCookies.length; i++) {
					
						//pole helperIDS je id vsetkych vystrah, pomocne pole, aby som vedel vyhladavat v tomto poli, podla vyskytu vymazem z hlavneho pola cely zaznam
						//po vymazani ho vzdy aktualizujem, aby mi sedeli idcka
						var helperIDS = Array();
						for(var k = 0; k < self.alerts.length; k++){
							helperIDS.push(self.alerts[k].id);
						}
						//console.log('helperIDS')
						//console.log(helperIDS)
					
						//console.log("hladam " + oldCookies[i])
						//console.log("v");
						//console.log(helperIDS);
						
						var a = helperIDS.indexOf(parseInt(oldCookies[i]));
						//console.log(a)
						
						if(a > -1){
							//console.log("mazem id " + oldCookies[i]);
							//console.log("mazem a " + a);
							self.alerts.splice(a, 1);
						}
					}
					
				}
				
				
				
			})
				.catch(function (error) {
				console.log(error);
			});
		},
		
		//close alert
		closeAlert:function(alertId,eventId,index){
			//console.log(alertId);
			var idska = Array();
			var oldCookies = Array();
			var x = this.getCookie("closedAlerts"+eventId);   
			if (x == '') {
				//cookies este neexistuje
				//console.log('cookies este neexistuje');
				idska.push(alertId);
				//console.log(idska);
				this.setCookie("closedAlerts"+eventId, idska.join(), this.cookiesTime);
			}else{
				//cookies uz existuje
				//console.log('cookies uz existuje');
				//console.log(x);
				oldCookies = x.split(",");
				//console.log(oldCookies);
				//var ids = Array();
				for(var i = 0; i < oldCookies.length; i++) {
					idska.push(parseInt(oldCookies[i]));
				}
				//console.log(x);
				idska.push(parseInt(alertId));
				this.setCookie("closedAlerts"+eventId, idska.join(), this.cookiesTime);
			}
			
			//vymazem aktualny zaznam
			this.alerts.splice(index, 1);
			
		},
		
		//zobrazenie menu
		showHideMenu:function(){
			if(this.menuIsOpened){
				this.menuIsOpened=false;
			}else{
				this.menuIsOpened=true;
			}
		},
		
		//modal logout Event
		loadModalLogOutEvent:function(){
			$('#myModalLogOutEvent').addClass('modal');
			$('#myModalLogOutEvent').modal({backdrop: "static"});
		},
		
	},
	
	created(){
		if(localStorage.Lang!=null) this.$i18n.locale=localStorage.Lang; 
	},
}
</script>


<template>

<div id="msg"></div>


<div class="container-fluid">
			
	<div class="row">
				
		<div class="col-10">
			<div class="mainMenu">
					<router-link to="/home"><font-awesome-icon :icon="['fas', 'home']" /></router-link>
				
				
					<router-link to="/check-tickets-qr" v-if="user.isReg" title="kamera" ><font-awesome-icon :icon="['fas', 'camera']" /></router-link>
					
				
				
					<router-link to="/check-tickets-line" v-if="user.isReg" title="čítačka"><font-awesome-icon :icon="['fas', 'qrcode']" /></router-link>
				
				
				
					<router-link to="/check-tickets-manual" v-if="user.isReg" title="ručne"><font-awesome-icon :icon="['fas', 'th-list']" /></router-link>
				
				
				<!--<router-link to="/streams" v-if="event.isReg && eventCounts.streams>0">{{ $t('message.LiveStream') }}</router-link>
				<router-link to="/documents" v-if="event.isReg && eventCounts.documents>0">{{ $t('message.Documents') }}</router-link>
				<router-link to="/exhibitors" v-if="event.isReg && eventCounts.expo>0">{{ $t('message.Expo') }}</router-link>
				<router-link to="/program" v-if="event.isReg && eventCounts.program>0">{{ $t('message.Program') }}</router-link>
				<router-link to="/speakers" v-if="event.isReg && eventCounts.speakers>0">{{ $t('message.Speakers') }}</router-link>
				<router-link to="/records" v-if="event.isReg && eventCounts.records>0">{{ $t('message.Records') }}</router-link>
				<router-link to="/alerts" v-if="user.isReg">{{ $t('message.Alerts') }}</router-link>
				<router-link to="/check-tickets-qr" v-if="user.isReg">{{ $t('message.CheckTickets') }}</router-link>
				<router-link to="/check-tickets-line" v-if="user.isReg">{{ $t('message.CheckTickets') }}</router-link>-->
			
				<span v-if="!user.isReg"  v-on:click="loadModalLogin()"><font-awesome-icon :icon="['fas', 'sign-in-alt']" /></span>
				
				
				<span  v-if="user.isReg" class="burger" v-on:click="showHideMenu()">
					<span v-if="!menuIsOpened"><font-awesome-icon :icon="['fas', 'bars']" /></span>
					<span v-else><font-awesome-icon :icon="['fas', 'times']" /></span>
					
					<span class="logOut" v-on:click="logOutMe()"><button class="btn btn-danger btn-sm">{{ $t('message.LogOut') }} <font-awesome-icon :icon="['fas', 'sign-out-alt']" /></button></span>
				</span>
				
				
			</div>
		</div>
		
		<myLangs v-model:propActLang="$i18n.locale"></myLangs>
				
	</div>
	
	<div  v-if="user.isReg" class="menu" :class="{'opened':menuIsOpened}">
		<ul class='main-menu'>
			<li>
				<router-link to="/alerts" v-if="user.isReg" @click="showHideMenu">{{ $t('message.Alerts') }}</router-link>
			</li>	
			
			<li>
				<router-link to="/check-tickets-qr" v-if="user.isReg" @click="showHideMenu">{{ $t('message.CheckTickets') }} - kamera</router-link>
			</li>	
			
			<li>
				<router-link to="/check-tickets-line" v-if="user.isReg" @click="showHideMenu">{{ $t('message.CheckTickets') }} - čítačka</router-link>
			</li>
			
			<li>
				<router-link to="/check-tickets-manual" v-if="user.isReg" @click="showHideMenu">{{ $t('message.CheckTickets') }} - ručne</router-link>
			</li>
			
			<li> 
				<span class="userMenu" v-if="user.isReg" v-on:click="logOutMe();showHideMenu()"><button class="btn btn-danger btn-sm">{{ $t('message.LogOut') }} <font-awesome-icon :icon="['fas', 'sign-out-alt']" /></button></span>
			</li>
		</ul>
	</div>
	
</div>


<section v-if="event.isReg" id="menuBottom">
<div class="container-fluid">
	<div class="row">
		<div class="col-12">
				<div class="row">
									
					<div class="col-2 box-0 box-flex text-center">
						<div v-if="eventCounts.streams>0" class="full">
							<router-link to="/streams" v-if="event.isReg" class="myItem"><div class="icon"><font-awesome-icon :icon="['fas', 'play-circle']" /></div><div class="description">Live</div></router-link>
						</div>
						<div v-else class="empty">
							<div class="icon"><font-awesome-icon :icon="['fas', 'play-circle']" /></div>
							<div class="description">Live</div>
						</div>
					</div>
				
				
					<div class="col-2 box-0 box-flex text-center">
						<div v-if="eventCounts.documents>0" class="full">
							<router-link to="/documents" v-if="event.isReg" class="myItem"><div class="icon"><font-awesome-icon :icon="['fas', 'file-alt']" /></div><div class="description">{{ $t('message.Documents') }}</div></router-link>
						</div>
						<div v-else class="empty">
							<div class="icon"><font-awesome-icon :icon="['fas', 'file-alt']" /></div>
							<div class="description">{{ $t('message.Documents') }}</div>
						</div>
					</div>
					
					
					<div class="col-2 box-0 box-flex text-center">
						<div v-if="eventCounts.expo>0" class="full">
							<router-link to="/exhibitors" v-if="event.isReg" class="myItem"><div class="icon"><font-awesome-icon :icon="['fas', 'globe']" /></div><div class="description">{{ $t('message.Partners') }}</div></router-link>
						</div>
						<!--<div v-else class="empty">
							<div class="icon"><font-awesome-icon :icon="['fas', 'globe']" /></div>
							<div class="description">{{ $t('message.Expo') }}</div>
						</div>-->
					</div>
				
				
					<div class="col-2 box-0 box-flex text-center">
						<div v-if="eventCounts.program>0" class="full">
							<router-link to="/program" v-if="event.isReg" class="myItem"><div class="icon"><font-awesome-icon :icon="['fas', 'list-alt']" /></div><div class="description">{{ $t('message.Program') }}</div></router-link>
						</div>
						<div v-else class="empty">
							<div class="icon"><font-awesome-icon :icon="['fas', 'list-alt']" /></div>
							<div class="description">{{ $t('message.Program') }}</div>
						</div>
					</div>
				
					
				
					<div class="col-2 box-0 box-flex text-center">
						<div v-if="eventCounts.speakers>0" class="full">
							<router-link to="/speakers" v-if="event.isReg" class="myItem"><div class="icon"><font-awesome-icon :icon="['fas', 'user-friends']" /></div><div class="description">{{ $t('message.Speakers') }}</div></router-link>
						</div>
						<div v-else class="empty">
							<div class="icon"><font-awesome-icon :icon="['fas', 'user-friends']" /></div>
							<div class="description">{{ $t('message.Speakers') }}</div>
						</div>
					</div>
					
				
					<div class="col-2 box-0 box-flex text-center">
						<div v-if="eventCounts.records>0" class="full">
							<router-link to="/records" v-if="event.isReg" class="myItem"><div class="icon"><font-awesome-icon :icon="['fas', 'video']" /></div><div class="description">{{ $t('message.Records') }}</div></router-link>
						</div>
						<div v-else class="empty">
							<div class="icon"><font-awesome-icon :icon="['fas', 'video']" /></div>
							<div class="description">{{ $t('message.Records') }}</div>
						</div>
					</div>
					
					
				</div>	
				
				
		</div>
			
	</div>
</div>
</section>

	<section v-if="event.isReg" id="hlavicka">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12 col-sm-8 col-md-8 box-flex-left">
					<h5>{{event.title}} <a v-if="event.urlQuestions && user.isReg" :href="event.urlQuestions" class="urlToQuestions" target="_blank">{{$t('message.Questions')}}</a></h5>
				</div>
				
				<div class="col-12 col-sm-4 col-md-4 logout text-right">
					<span v-on:click="loadModalLogOutEvent()" class="btn btn-sm btn-danger q-logout">{{ $t('message.LoginToAnotherEvent') }} </span>
				</div>
			</div>
		</div>
	</section>

	<div>
		<router-view :myUserReg="checkRegisterUser" :myEventReg="checkRegisterEvent" :myEventCounts="eventCounts" :myEventTitle="event.title" :event="event"></router-view>
	</div>

	
	
	<section id="paticka">
		<div class="container">
			<div class="row">

				<div class="col-12 offset-md-4 col-md-4 text-center">
					<p class="">
						<!--<a href="https://pp.sk" target="_blank">-->
							<img v-if="event.confFileLogo" class="img-fluid" :src='"/images/"+event.confFileLogo' alt="Studio W" title="Studio W">
							<img v-else class="img-fluid" src="/images/studiow_red.svg" alt="Studio W" title="Studio W">
						<!--</a>-->
						<br>
						</p><div v-if="!event.confFileLogo" class="subtitle" v-on:click="loadAlerts()"><p>{{ $t('message.30yearsWithYou') }}</p></div>
						
					<p></p>				
				</div>
			</div>
		</div>
	</section>

	<section v-if="event.isReg && alerts.length>0" id="alerts">
		<div class="container">
			<!--<div class="row">
				<div class="col-12">
					<h3><font-awesome-icon :icon="['fas', 'times']" /></h3>
				</div>
			</div>-->
			
			<div class="row">
				<div v-for="(item,index) in alerts" :key="item" class="col-12 col-md-4 offset-md-4 alertType" :class="item.typeName">
					<div class="row">
						<div class="col-10">
							<p :class="item.typeName">{{item.title}}</p>
						</div>
						<div class="col-2 box-flex">
							<span class="closeAlert" v-on:click="closeAlert(item.id,item.eventId,index)"><font-awesome-icon :icon="['fas', 'times']" /></span>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</section>

	<div id="myModalLogin" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog" style="">
			<div class="modal-content">
				
				<div class="modal-header">
					<h6 class="modal-title"><span>{{ $t('message.Admin') }}</span></h6>
					<div class="modal-button-close" v-on:click="closeModalBoxSecure('myModalLogin')" ><font-awesome-icon :icon="['fas', 'times']" /></div>
					
				</div>
		
				<div class="modal-body">
					<div class="row">
						<div class="col-12">
						
							<div class="form-row">
								<div class="form-group col-12">
									<input class="form-control form-control-sm" type="text" v-model="user.email" :placeholder="$t('message.Email')"/>
								</div>
							</div>
							
							<div class="form-row">
								<div class="form-group col-12" >
									<input :type="user.showInput ? 'text' : 'password'" autocomplete="new-password" class="form-control form-control-sm" v-model="user.password" :placeholder="$t('message.Password')">
									
								</div>
							</div>
							
							<div class="form-row">
								<div class="form-group col-12 text-right" >
									
									<span class="btn btn-link btn-sm" v-on:click="showPswd()">
										
										<span v-if="!user.showInput">{{ $t('message.showPassword') }} <font-awesome-icon :icon="['fas', 'eye']" /></span>
										
										<span v-else>{{ $t('message.hidePassword') }}<font-awesome-icon  :title="$t('message.hidePassword')" :icon="['fas', 'eye-slash']" /></span>
									</span>
								</div>
							</div>
							
							<div class="row">
								
								<div class="col-12 text-right">
									<button v-on:click="signMe()" class="btn btn-warning btn-sm">{{ $t('message.Login') }}</button>
								</div>
								
							</div>
								
						</div>
					</div>	
				</div>
				
			</div>
		</div>
	</div>
	
	
	
	<div id="myModalLogOutEvent" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog" style="">
			<div class="modal-content">
				
				<div class="modal-header">
					<h6 class="modal-title"><span>{{ $t('message.doYouWantRealyLogOutEvent') }}</span></h6>
					<div class="modal-button-close" v-on:click="closeModalBoxSecure('myModalLogOutEvent')" ><font-awesome-icon :icon="['fas', 'times']" /></div>
				</div>
		
				<div class="modal-body">
					<div class="row">
						<div class="col-12">
						
							<div class="row">
								<div class="col-12 text-right">
									<button v-on:click="logoutEvent()" class="btn btn-danger btn-sm">{{ $t('message.LogOut') }}</button>
								</div>
							</div>

						</div>
					</div>	
				</div>
				
			</div>
		</div>
	</div>

</template>

<style lang="scss">
	@import "./assets/scss/style.scss";
</style>